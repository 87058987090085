import React from 'react';
import PropTypes from 'prop-types';
import plane from '../../assets/images/plane.png';
import undoLeftRound from '../../assets/images/undoLeftRound.svg';
import undoRightRound from '../../assets/images/undoRightRound.svg';
import eyeOutline from '../../assets/images/eyeOutline.svg';
import fileText from '../../assets/images/fileText.svg';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const TestHeader = ({ icon, description, content }) => {
  return (
    <div className={'test-header'}>
      <div className="content-box">
        <div className="route-status">
          <img src={icon} alt="" />
          <div className="manual-routes">
            <p>{description}</p>
          </div>
        </div>
        {content}
      </div>
    </div>
  );
};

TestHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TestHeader;
