import { useState } from 'react';
import StepProgress from './authSteps/StepProgress';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import settings from '../../../../assets/images/settings.png';
export const DomainAuth = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    { title: 'Start your email authentication process' },
    { title: 'Go to your domain provider’s website' },
    { title: 'Create a CNAME record using Dinomail info' },
    { title: 'Wait for Dinomail to check the record' },
  ];

  const desctructuredSteps = steps.map((item, i) => {
    return (
      <div key={i}>
        <span>{item.title}</span>
      </div>
    );
  });
  const navigate = useNavigate();
  const handleNav = () => {
    navigate('/settings');
  };
  return (
    <div className={'domain-auth'}>
      <div className="header">
        <div className="content-box">
          <div className="route-status">
            <img src={settings} alt="header-settings" />
            <span onClick={handleNav} className={'settings-span'}>
              Settings
            </span>
            <span>/</span>
            <a>Domain authentication</a>
          </div>
          <button className={'filled'} onClick={handleNav}>
            <div>Save & exit</div>
          </button>
        </div>
      </div>
      <div className={'steps-body'}>
        <StepProgress steps={desctructuredSteps} currentStep={currentStep} />
      </div>
    </div>
  );
};
