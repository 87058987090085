import { createPortal } from 'react-dom';
import { TextIconWrapper } from '../wrappers/TextIconWrapper';
import { RoundCloseIcon } from '../interface/Buttons/RoundCloseIcon';
import { DinoInput } from '../inputComponent/DinoInput';
import { DinoButton } from '../interface/Buttons/DinoButton';
import { CloseIcon } from '../interface/icons/CloseIcon';
import './styles.css';

export const SendMailModal = ({ isOpenModal, onClose }) => {
  if (!isOpenModal) return null;
  return createPortal(
    <div className="modal-wrapper">
      <div className="modal-shadow" onClick={onClose} />
      <div className="modal-container">
        <TextIconWrapper className="modal-header">
          <h3>Send email</h3>
          <RoundCloseIcon onClick={onClose} />
        </TextIconWrapper>
        <div className="modal-content">
          <DinoInput
            rightIcon={<CloseIcon onClick={() => {}} />}
            value=""
            onChange={() => {}}
            label="Recipients"
            placeholder="example@mail.com. example2@mail.com"
          />
        </div>
        <div className="modal-footer">
          <DinoButton className="modal-button" text="Cancel" variant="passive" onClick={onClose} />
          <DinoButton className="modal-button" text="Send" variant="active" />
        </div>
      </div>
    </div>,
    document.getElementById('send-mail-modal'),
  );
};
