import './style.scss';
import emails from '../../../../assets/images/emails.png';
import abTesting from '../../../../assets/images/switch-button.svg';
import journey from '../../../../assets/images/journey.png';
import { useState } from 'react';
import { CampaignModal } from '../createCampaignModal/CampaignModal';

export const SubHeader = ({ handleOpenModal }) => {
  return (
    <div className={'upload-campaign'}>
      <div className="cards">
        <div className="email" onClick={handleOpenModal}>
          <img src={emails} alt="upload" />
          <div className="upload-des">
            <h4>Email</h4>
            <p>Send email newsletters, announcements and more</p>
          </div>
        </div>
        <div className="abTesting">
          <div className="cooming-soon">
            <span>Soon</span>
          </div>
          <img src={abTesting} alt="upload" />
          <div className="upload-des">
            <h4>A/B Testing</h4>
            <p>Identify the most effective campaign options</p>
          </div>
        </div>
        <div className="journey">
          <div className="cooming-soon">
            <span>Soon</span>
          </div>
          <img src={journey} alt="upload" />
          <div className="upload-des">
            <h4>Import</h4>
            <p>Visually automate messages and actions over time </p>
          </div>
        </div>
      </div>
    </div>
  );
};
