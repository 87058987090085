import authImg from '../../assets/images/auth-picture.jpg';
import React, { useContext, useState } from 'react';

export const VerifyPage = () => {
  return (
    <div className={'verify'}>
      <div>
        <h1>Email confirmation</h1>
        <p>please confirm the email address you provided during registration</p>
      </div>
      <div className={'img-wrapper'}>
        <img src={authImg} className={'verify-img'} />
      </div>
    </div>
  );
};
