import './style.scss';
import { useState } from 'react';
import authImg from '../../assets/images/auth-picture.jpg';

export const ForgotPassword = () => {
  const [email, setEmail] = useState({ value: '', error: '' });
  const [isSubmit, setIsSubmit] = useState(false);
  const [isFilled, setisFilled] = useState(false);

  const handleChangeEmail = (e) => {
    setEmail({ value: e.target.value, error: '' });
    setisFilled(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isError = false;
    if (!email.value) {
      setEmail({ value: '', error: 'Email is require' });
      isError = true;
    }
    if (email.value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.value)) {
      isError = true;
      setEmail({ value: '', error: 'Email incorrect' });
    }
    if (!isError) {
      setEmail({ value: '', error: '' });
      setIsSubmit(true);
    }
  };

  return (
    <div className={'forgot-pass'}>
      <div className={'form-wrapper'}>
        <h1>Forgot password?</h1>
        <p>
          Enter the email address you used when you joined and we’ll send you instructions to reset
          your password.
        </p>
        <form onSubmit={handleSubmit} className={'form'}>
          <div className={'email'}>
            <span className={'email-label'}>Email address</span>
            <input
              placeholder={'youremail@mail.com'}
              onChange={handleChangeEmail}
              value={email.value}
              type={'text'}
              className={'email-field'}
            />
          </div>
          <button
            type={'submit'}
            className={isFilled ? 'sign-in' : 'sign-disabled'}
            disabled={!isFilled}>
            <div>Send Reset Instruction</div>
          </button>
        </form>
      </div>
      <div className={'img-wrapper'}>
        <img src={authImg} alt={'auth-img'} className={`authImg`} />
      </div>
    </div>
  );
};
