import { StatCards } from './statCards/StatCards';
import CircleChart from '../../../pages/homePage/dashboard/graphs/circleChart/DoughnutChart';
import { LineChart } from '../../../pages/homePage/dashboard/graphs/lineChart/MyLineChart';
import { ImportStatsChart } from '../../../pages/homePage/dashboard/graphs/barCharts/importStats/ImportStatChart';
import TimeBarChart from '../../../pages/homePage/dashboard/graphs/timeBarChart/TimeBarChart';
import ProgressBarComponent from '../../../pages/homePage/dashboard/progressbarComponent/ProgressBarComponent';
import { DevicesStat } from '../../../pages/homePage/dashboard/devicesStats/DevicesStat';
import { LanguagesStat } from '../../../pages/homePage/dashboard/languageStat/LanguagesStat';
import { SubsGeoStat } from '../../../pages/homePage/dashboard/subsGeo/SubsGeoStat';
import MapBubbleChart from '../../../pages/homePage/dashboard/graphs/mapChart/MapChart';
import React from 'react';
import './style.scss';
export const OverallTab = () => {
  return (
    <div className={'overall-tab-wrapper'}>
      <StatCards />
      <CircleChart />
      <LineChart />
      <div className={'vertical-charts'}>
        <ImportStatsChart />
        <ImportStatsChart />
      </div>
      <TimeBarChart />
      <div className={'pg-main-wrapper'}>
        <ProgressBarComponent />
        <ProgressBarComponent />
      </div>
      <div className={'device-lang-stats'}>
        <DevicesStat />
        <LanguagesStat />
      </div>
      <div className={'map-chart-wrapper'}>
        <div className={'map-title'}>Subscribers Geographic</div>
        <div className={'data'}>
          <SubsGeoStat />
          <MapBubbleChart />
        </div>
      </div>
    </div>
  );
};
