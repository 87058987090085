import './styles.css';

export const DinoInput = ({ label, leftIcon, rightIcon, placeholder, value, onChange }) => {
  return (
    <div className="dino-input-icon-block">
      <label className="dino-label-styles" htmlFor="dino-field">
        {label}
      </label>

      <div className="dino-input-icon-wrapper">
        {leftIcon}
        {rightIcon}
        <input
          value={value}
          onChange={onChange}
          id="dino-field"
          type="text"
          className={`dino-input-styles${leftIcon ? ' is-active-icon' : ''}`}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
