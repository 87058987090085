import './style.scss';
import settings from '../../../assets/images/settings.png';
import TabComponent from '../../../components/tabComponent/TabComponent';
import TestHeader from '../../../components/header/TestHeader';
export const Settings = () => {
  return (
    <div className={'settings'}>
      <TestHeader icon={settings} description={'Settings'} />
      <div className={'settings-content'}>
        <div className={'settings-title'}>
          <h1>Settings</h1>
          <p>Here you can edit information about yourself and your company.</p>
        </div>
        <div className={'tabs-wrapper'}>
          <TabComponent />
        </div>
      </div>
    </div>
  );
};
