import { MODAL_CARDS } from '../../../../constants/campaignsConstants';
import close from '../../../../assets/images/xblack.png';
import { useNavigate } from 'react-router-dom';
import './style.scss';
export const CampaignModal = ({ closeModal }) => {
  const navigate = useNavigate();

  return (
    <div className={'cam-modal-wrapper'}>
      <div className={'cam-modal'}>
        <div className={'cam-title'}>
          <h1 className={'modal-title'}>Create new email campaign</h1>
          <p className={'modal-title-text'}>Choose a method for crafting your email</p>
          <div className={'close-btn'} onClick={closeModal}>
            <img src={close} alt={'close-modal'} className={'close-modal'} />
          </div>
        </div>
        <div className={'cam-modal-cards'}>
          {MODAL_CARDS.map((card, i) => {
            return (
              <div
                onClick={() => card.path && navigate(card.path)}
                className={`modal-card`}
                key={i}
                style={{ background: card.background }}
                data-hover={card.hover}>
                <img src={card.img} alt={'modal-campaign-variant'} className={'modal-main-img'} />
                {card.img2 && <img src={card.img2} alt={'card-star'} className={'star-icon'} />}
                <span>{card.title}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
