import InputComponent from './inputCopmponent';
import { Button } from './nextButton';

export const ThirdForm = ({ handleNextStep }) => {
  return (
    <div className={'third-form-content'}>
      <p>
        On GoDaddy, you are going to add 2 CNAME records. These record help Dinomail direct your
        email to the right place
      </p>
      <ul>
        <li> On the Domain manager page, click the Add button.</li>
        <li> For Type, choose CNAME from the drop-down menu.</li>
        <li> For Host, copy/paste the Host info from CNAME 1 below.</li>
        <li> For Points To, copy/paste the Points To into from CNAME 1 below.</li>
        <li> Leave TTL at the default settings</li>
        <li> Click the Save button.</li>
      </ul>
      <a>Now repeat those steps, but this time copy/paste the info for CNAME 2.</a>
      <div className={'cnames'}>
        <div className={'cname_1'}>
          <p>CNAME 1</p>
          <InputComponent labelText="Host" placeholderText="k2_domainkey" />
          <InputComponent labelText="Points To" placeholderText="dkim2.mcsv.net" />
        </div>
        <div className={'horizontal-line'} />
        <div className={'cname_2'}>
          <p>CNAME 2</p>
          <InputComponent labelText="Host" placeholderText="k2_domainkey" />
          <InputComponent labelText="Points To" placeholderText="dkim2.mcsv.net" />
        </div>
      </div>
      <Button onClick={handleNextStep} name={'Next'} />
    </div>
  );
};
