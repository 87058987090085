import { Button } from './nextButton';

export const SuccessStatus = () => {
  return (
    <div className={'success-status'}>
      <p>Success!</p>
      <span>Dynomail processed your entry and successfully approved it</span>
      <Button name={'Check status'} />
    </div>
  );
};
