import './style.scss';
import noStatsCampaigns from '../../../assets/images/Dashboard/Frame 981287.svg';
export const CampaignsTab = () => {
  return (
    <div className={'campaigns-tab-wrapper'}>
      <div className={'campaigns-tab-placeholder'}>
        <img src={noStatsCampaigns} alt={'no-camp-stats'} />
        <span className={'sub-title'}>There are no campaigns here yet</span>
      </div>
    </div>
  );
};
