import './style.scss';
import dinologo from '../../assets/images/logoRedesigned.png';
import eye from '../../assets/images/eye.png';
import slasheye from '../../assets/images/eyeslash.png';
import alert from '../../assets/images/alert_circle.png';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import registerMark from '../../assets/images/Vector1.png';
import { useDispatch, useSelector } from 'react-redux';
import { sendCode2FAh2FATokener, registerUser } from '../../store/userSlice';

export const RegisterTestForm = ({ onClick, toggleImg }) => {
  const [email, setEmail] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [viewPassword, setViewPassword] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const { loading, error } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    console.log('start register request');
    e.preventDefault();
    let isError = false;
    const userCredentials = {
      login: email.value,
      password: password.value,
    };
    if (!userCredentials.login) {
      setEmail({ value: '', error: 'Email is require' });
      isError = true;
    }
    if (
      userCredentials.login &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userCredentials.login)
    ) {
      isError = true;
      setEmail({ value: '', error: 'Email incorrect' });
    }
    if (!userCredentials.password) {
      setPassword({ value: '', error: 'Please, enter a password' });
      isError = true;
    }
    if (userCredentials.password.length < 8) {
      isError = true;
      setPassword({ value: '', error: 'Your password must contain at least 8 characters' });
    }
    if (!isError) {
      setPassword({ value: '', error: '' });
      setEmail({ value: '', error: '' });
      setIsSubmit(true);
      dispatch(registerUser(userCredentials)).then(async (result) => {
        if (result) {
          setPassword({ value: '', error: '' });
          setEmail({ value: '', error: '' });
          navigate('/verify');
        } else {
          console.log('login error');
        }
      });
    }
    console.log(isError, 'error');
  };

  const togglePassword = () => {
    setViewPassword(!viewPassword);
  };
  return (
    <div className={'reg-wrapper'}>
      <div className={'register'}>
        <img src={registerMark} alt={'mark'} className={'mark'} />
        <div className={'logo'}>
          <img src={dinologo} alt={'logo'} />
        </div>
        <h1 className={'form-title'}>Sign up for Dinomail</h1>
        <form className={'reg-form'} onSubmit={handleSubmit}>
          <div className={'email'}>
            <span className={'reg-email-label'}>Email address</span>
            <input
              placeholder={'youremail@mail.com'}
              onChange={(e) => setEmail({ value: e.target.value, error: '' })}
              value={email.value}
              type={'text'}
              className={'reg-email-field'}
            />
          </div>
          <div className={'pass'}>
            <label>Password</label>
            <div className={'pass-input'}>
              <input
                value={password.value}
                type={viewPassword ? 'text' : 'password'}
                onChange={(e) => setPassword({ value: e.target.value, error: '' })}
                placeholder={'Enter your password'}
                className={'reg-pass-field'}
              />
              <img
                onClick={togglePassword}
                src={viewPassword ? slasheye : eye}
                alt={'eye'}
                className={'eye'}
              />
            </div>
            <p
              className={!isSubmit ? 'error-show' : 'error-hide'}
              style={!isSubmit ? { display: 'flex' } : { display: 'none' }}>
              {password.error || email.error}
            </p>
          </div>
          <button
            type={'submit'}
            className={email.value.length ? 'sign-up' : 'sign-disabled'}
            disabled={!(email.value && password.value)}>
            <div>{loading ? 'Loading ...' : 'Create an account'}</div>
          </button>
          <div className={'privacy'}>
            <img src={alert} alt={'alert'} />
            <p>
              By clicking on the “Create an account” button you’re agreeing with our{' '}
              <a>Privacy Policy</a> and <a>Terms and Conditions.</a>
            </p>
          </div>
        </form>
      </div>
      {error && <div className={'request-error'}>{error}</div>}
      <span className={'log-in'}>
        Already have an account?
        <p onClick={onClick} onToggle={() => toggleImg}>
          Log In
        </p>
      </span>
    </div>
  );
};
