import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element }) => {
  const isLogged = useSelector((state) => state.user.isLogged);
  console.log(isLogged, 'isLogged');
  // return isLogged ? element : <Navigate to="/loginpage" />;
  return element;
};

export default PrivateRoute;
