import InputComponent from '../../../../../../components/inputComponent/InputComponent';
import CustomDropdown from '../../../../../../components/dropdownComponent/CustomDropdown';
import './style.scss';
import { domain_provider } from '../../../../../../constants';
import { useEffect, useState } from 'react';

export const FirstForm = ({ handleNextStep }) => {
  const [domainName, setDomainName] = useState('');
  const options = domain_provider;
  const handleSelect = (selectedOption) => {
    setDomainName(selectedOption);
    console.log(`Selected option: ${selectedOption}`);
  };
  const handleChangeName = (e) => {
    setDomainName(e.target.value);
  };
  const isButtonDisabled = !domainName || !handleSelect;
  useEffect(() => {
    console.log('error');
  }, [domainName, handleSelect]);
  return (
    <div className={'form-content'}>
      <p className={'step-description'}>
        Select your corporate domain, which will be used to send email campaigns from your email
        addresses.
      </p>
      <div className={'inputs-box'}>
        <InputComponent
          placeholder={'Mail subject'}
          label={'Domain name'}
          onChange={handleChangeName}
        />
        <CustomDropdown label={'Domain provider'} options={options} onSelect={handleSelect} />
      </div>
      <button onClick={handleNextStep} disabled={isButtonDisabled}>
        Next
      </button>
    </div>
  );
};
