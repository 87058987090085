import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ReactSortable } from 'react-sortablejs';
import { MailContext } from '../MailBuilderPage';
import { initMobileMediaQuery } from '../initial.constants';
import './styles.css';

const LayoutChildBlock = ({ editorBlock, childBlock, childIdx }) => {
  const [grandChildID, setGrandChildID] = useState('');

  const {
    setMailEditorState,
    selectedBlockID,
    setSelectedBlockID,
    activeTab,
    showMailPreview,
    mediaQuery,
  } = useContext(MailContext);

  const onUpdateLayoutChild = (updatedState) => {
    setMailEditorState((prevState) => {
      return prevState.map((prevStateElement) => {
        if (prevStateElement.id === editorBlock.id) {
          return {
            ...prevStateElement,
            child: prevStateElement.child.map((childItem) => {
              if (childItem.id === childBlock.id) {
                const updatedChildren =
                  updatedState.length <= 1 ? [...updatedState] : childItem.children;
                return {
                  ...childItem,
                  children: updatedChildren,
                };
              }
              return childItem;
            }),
          };
        }
        return prevStateElement;
      });
    });
  };

  const onDeleteLayoutChild = (id) => {
    setMailEditorState((prevState) => {
      return prevState.map((prevStateElement) => {
        if (prevStateElement.id === editorBlock.id) {
          return {
            ...prevStateElement,
            child: prevStateElement.child.map((childItem) => {
              const ch = childItem.children?.some((grandChild) => grandChild.id === id);
              if (childItem.id === childBlock.id && ch) {
                return {
                  ...childItem,
                  children: [],
                };
              }
              return childItem;
            }),
          };
        }
        return prevStateElement;
      });
    });
  };

  const isDropContentHere = childBlock.children.length
    ? ''
    : !showMailPreview
    ? ' drop-your-content-here'
    : '';
  const isActiveLayoutTab = activeTab === ' layout-blocks-tab' ? ' layout-tab-active' : '';
  const isActiveDragIcon =
    !showMailPreview && grandChildID === selectedBlockID ? ' is-active-icon-drag' : '';

  const isPreviewMobileMode =
    showMailPreview && mediaQuery === initMobileMediaQuery ? ' is-layout-child-mobile-mode' : '';

  const isPreviewWidth =
    showMailPreview && mediaQuery === initMobileMediaQuery ? '100%' : childBlock.width[childIdx];

  return (
    <ReactSortable
      list={childBlock.children}
      setList={(updatedState) => onUpdateLayoutChild(updatedState)}
      key={childBlock.id}
      id={childBlock.id}
      group={{
        name: 'layout-child',
        put: ['orthography-list'],
      }}
      animation={200}
      clone={(item) => ({ ...item, id: uuidv4(), width: childBlock.width[childIdx] })}
      style={{ width: isPreviewWidth }}
      className={`layout-child${isDropContentHere}${isActiveLayoutTab}${isActiveDragIcon}${isPreviewMobileMode}`}>
      {childBlock.children.map((ch) => {
        const Component = ch.content;
        return (
          <Component
            key={ch.id}
            id={ch.id}
            isActive={ch.id === selectedBlockID}
            text={ch.text}
            showMailPreview={showMailPreview}
            onClickRemoveBuildBlock={() => onDeleteLayoutChild(ch.id)}
            onClick={(e) => {
              e.stopPropagation();
              if (!showMailPreview) {
                setSelectedBlockID(ch.id);
                setGrandChildID(ch.id);
              }
            }}
          />
        );
      })}
    </ReactSortable>
  );
};

export const LayoutContainerBlock = ({ editorBlock }) => {
  const { setMailEditorState, showMailPreview, mediaQuery } = useContext(MailContext);

  const isPreviewMobileMode =
    showMailPreview && mediaQuery === initMobileMediaQuery
      ? ' is-layout-container-mobile-mode'
      : '';

  return (
    <ReactSortable
      list={editorBlock?.child}
      setList={(childList) => {
        setMailEditorState((prevState) => {
          return prevState.map((prevStateElement) => {
            if (prevStateElement.id === editorBlock.id) {
              const blockIndex = prevStateElement?.child.findIndex((item) => item.chosen);
              // result.sort((a, b) => a.index - b.index);
              // const prevStateCopy = [...prevStateElement.child].sort((a, b) => a.chosen - b.chosen);
              return {
                ...prevStateElement,
                child: [...childList].map((ch) => ({ ...ch })),
                // width:
                //   prevStateElement?.width?.map((item, idx) => prevStateCopy[idx]?.width || item) ||
                //   prevStateElement?.width,
                // child: prevStateCopy.map((ch) => ({ ...ch })),
              };
            }
            return prevStateElement;
          });
        });
      }}
      group={{
        name: 'layout-container',
        pull: true,
      }}
      clone={(item) => ({ ...item, id: uuidv4() })}
      animation={200}
      className={`layout-container${isPreviewMobileMode}`}>
      {editorBlock?.child.map((childBlock, childIdx) => (
        <LayoutChildBlock
          key={childBlock.id}
          editorBlock={editorBlock}
          childBlock={childBlock}
          childIdx={childIdx}
        />
      ))}
    </ReactSortable>
  );
};
