import { Button } from './nextButton';

export const SecondForm = ({ handleNextStep }) => {
  return (
    <div className={'second-form-content'}>
      <p>
        Open a new tab or window and navigate to the GoDaddy website. Then follow these steps to get
        to your domains area.
      </p>
      <ul>
        <li>Log into your Godaddy Domain Control Center.</li>
        <li>Select the domain you want to authenticate to access the Domain Settings page</li>
        <li>Under Adittional Settings, select Manage DNS</li>
      </ul>
      <a>Awesome, you’re ready for the next step!</a>
      <Button onClick={handleNextStep} name={'Next'} />
    </div>
  );
};
