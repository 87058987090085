// Custom hook to handle element resize
import { useEffect, useState } from 'react';

/**
 * useResizeObserver - A custom React hook using ResizeObserver API to monitor changes in the width of a specified HTML element.
 * @param {HTMLElement} element - The HTML element to be observed for size changes.
 * @param {function} callback - The callback function to be executed when the element's width changes.
 * @returns {void} - Returns nothing.
 */
export const useResizeObserver = (element, callback) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        callback(entry.contentRect.width);
      }
    });

    if (element) {
      resizeObserver.observe(element);

      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, [element, callback]);
};

/**
 * Finds a video build block in the given mail editor state by its ID.
 * @param {Array} mailEditorState - The array representing the mail editor state.
 * @param {string} videoBuildBlockId - The ID of the video build block to find.
 * @returns {Array} - An array containing the found video build block(s).
 */
export const findVideoBuildBlockByID = (mailEditorState, videoBuildBlockId) => {
  return mailEditorState.reduce((acc, el) => {
    if (el.id === videoBuildBlockId) {
      acc.push(el);
    } else if (el.child) {
      el.child.forEach((i) => {
        if (i.children) {
          acc.push(...i.children.filter((ch) => ch.id === videoBuildBlockId));
        }
      });
    }
    return acc;
  }, []);
};

export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  const handleChange = (e) => setMatches(e.matches);

  useEffect(() => {
    const m = window.matchMedia(query);

    setMatches(m.matches);

    m.addEventListener('change', handleChange);

    return () => {
      m.removeEventListener('change', handleChange);
    };
  }, [query]);

  return !matches;
};
