import './styles.css';

const InputComponent = ({ label, type, value, onChange, placeholder }) => {
  return (
    <div className={'input-box'}>
      <label>{label}</label>
      <input type={type} value={value} onChange={onChange} placeholder={placeholder} />
    </div>
  );
};

export default InputComponent;
