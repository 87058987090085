import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './style.scss';
import TestHeader from '../../../components/header/TestHeader';
import dashboard from '../../../assets/images/dashboardicon.png';
import { CampaignsTab } from '../../../components/dashboardTabComponent/campaigns/CampaignsTab';
import { OverallTab } from '../../../components/dashboardTabComponent/overall/OverallTab';
import DashboardTabs from '../../../components/dashboardTabComponent/DashboardTabs';
import DashboardDropdown from '../../../components/dashboardDropdown/DashboardDropdown';
import { setSelectedOption } from '../../../store/userSlice';

export const Dashboard = () => {
  const [selected, setSelected] = useState('');
  const [activeTab, setActiveTab] = useState('overall');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'campaigns':
        return <CampaignsTab />;
      case 'overall':
        return <OverallTab />;
      default:
        return null;
    }
  };

  const dispatch = useDispatch();
  const handleSelect = (selectedOption) => {
    setSelected(selectedOption);
    dispatch(setSelectedOption(selectedOption)); // dispatch action to update Redux state
  };

  const options = ['Year', 'Month', 'Week', 'Day'];

  const renderedDropdownPlaceholder = (
    <div className={'placeholder'}>
      <span>period</span> <div>{options[1]}</div>
    </div>
  );

  return (
    <div className={'dashboard-wrapper'}>
      <TestHeader icon={dashboard} description={'Dashboard'} />
      <div className={'dashboard-content'}>
        <h1 className={'statistic'}>Statistic</h1>
        <DashboardTabs
          activeTab={activeTab}
          onTabClick={setActiveTab}
          tabContent={renderTabContent()}
        />
        {activeTab === 'overall' ? (
          <DashboardDropdown
            options={options}
            onSelect={handleSelect}
            placeholder={renderedDropdownPlaceholder}
          />
        ) : (
          <div className={'compare-btn'}>
            <span>Compare</span>
          </div>
        )}
      </div>
      <div className={'tab-box'}>{activeTab === 'overall' ? <OverallTab /> : <CampaignsTab />}</div>
    </div>
  );
};
