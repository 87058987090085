import React, { useState, useEffect } from 'react';
import validated from '../../../../../assets/images/validCheckCircle.svg';
import disabled from '../../../../../assets/images/disabledCheckCircle.svg';
import voidCircle from '../../../../../assets/images/voidCheckCircle.svg';
import { FirstForm } from './forms/FirstForm';
import { SecondForm } from './forms/SecondForm';
import { ThirdForm } from './forms/ThirdForm';
import { FourthForm } from './forms/FourthForm';
const StepProgress = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);

  const handleStatusChange = (newStatus) => {
    setCurrentStatus(newStatus);
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length));
  };

  useEffect(() => {
    setCompletedSteps(Array.from({ length: currentStep }).fill(true));
  }, [currentStep, currentStatus]);

  return (
    <div className="step-progress">
      <div className={'steps-box'}>
        {steps.map((step, index) => (
          <div key={index} className={`step ${index === currentStep ? 'current' : ''}`}>
            <p>{step}</p>
            <div className="status-icons">
              {completedSteps[index] && (
                <>
                  {index === steps.length - 1 && currentStatus === 'success' ? (
                    <>
                      <img src={validated} alt="Check Mark" />
                      {index !== steps.length - 1 && <div className="line" />}
                    </>
                  ) : (
                    <>
                      <img src={validated} alt="Check Mark" />
                      {index !== steps.length - 1 && <div className="line" />}
                    </>
                  )}
                </>
              )}
              {index === currentStep && (
                <>
                  <img src={voidCircle} alt="Green Indicator" />
                  {index !== steps.length - 1 && <div className="line" />}
                </>
              )}
              {index > currentStep && (
                <>
                  <img src={disabled} alt="Disabled Indicator" />
                  {index !== steps.length - 1 && <div className="line" />}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={'forms'}>
        {currentStep === 0 && <FirstForm handleNextStep={handleNextStep} />}
        {currentStep === 1 && <SecondForm handleNextStep={handleNextStep} />}
        {currentStep === 2 && <ThirdForm handleNextStep={handleNextStep} />}
        {currentStep === 3 && (
          <FourthForm
            handleNextStep={handleNextStep}
            onStatusChange={handleStatusChange}
            disabled={currentStep === steps.length - 1}
          />
        )}
      </div>
    </div>
  );
};

export default StepProgress;
