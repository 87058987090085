import React from 'react';
import { Button } from './nextButton';

export const PendingStatus = ({ handleNextStep }) => {
  return (
    <div className="pending-status">
      <p>Pending...</p>
      <span>Dinomail is still processing your record</span>
      <Button disabled name={'Check status'} />
    </div>
  );
};
