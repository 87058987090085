import InputComponent from '../../inputComponent/InputComponent';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../../store/userSlice';
import PasswordInputComponent from '../../passInputComponent/PasswordInputComponent';
import { Button } from '../../../pages/homePage/settings/domainAuth/authSteps/forms/nextButton';
import checkCircle from '../../../assets/images/validCheckCircle.svg';
import axios from 'axios';
import { setToken2FA } from '../../../store/userSlice';

export const UserInfo = () => {
  const accessToken = localStorage.getItem('access_token');
  const userEmail = useSelector((state) => state.user.email);
  const [email, setEmailState] = useState(userEmail);
  const [savedEmail, setSavedEmail] = useState();
  const [passwordChange, setPasswordChange] = useState({ value: '', error: '' });
  const [isCorrectEmail, setIsCorrectEmail] = useState(false);
  const [isEnable2FA, setIsEnable2FA] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isFilled, setisFilled] = useState(false);
  const [reqErr, setReqErr] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token2fa = localStorage.getItem('token_2fa');
  const { loading, error } = useSelector((state) => state.user);

  const handleDisableEnableAuth = async () => {
    try {
      const req = await fetch('/api/settings/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ auth_2fa: !isEnable2FA, code_size: 6 }),
      });
      if (req.status !== 200) {
        throw new Error(`Error in 2FA login request: ${req.status}, ${await req.text()}`);
      }
      console.log(`2FA is ${!isEnable2FA ? 'enabled' : 'disabled'}`);
      setIsEnable2FA(!isEnable2FA);
    } catch (error) {
      console.error('Error in 2FA login request', error);
    }
  };

  const handleNav = () => {
    navigate('/verify', { replace: true });
  };

  useEffect(() => {
    // Fetch the saved email from Redux state when the component mounts
    const storedEmail = localStorage.getItem('userEmail');
    console.log(storedEmail, 'storedEmail');
    setSavedEmail(storedEmail);

    fetch('/api/settings/auth', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((res) => {
      res
        .json()
        .then((data) => {
          console.log(`2FA is ${data.auth_2fa ? 'enabled' : 'disabled'}`);
          setIsEnable2FA(data.auth_2fa);
        })
        .catch((error) => console.log(error, 'userinfo error'));
    });
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    let userRegisterData = {
      password: passwordChange.value,
    };
    if (!passwordChange.value) {
      setPasswordChange({ value: '', error: 'Please, enter a password' });
      isError = true;
    }
    if (passwordChange.value.length < 8) {
      isError = true;
      setPasswordChange({ value: '', error: 'Your password must contain at least 8 characters' });
    }
    if (!isError) {
      dispatch(registerUser(userRegisterData)).then((result) => {
        console.log(result, 'result of registering');
        if (result.payload) {
          setPasswordChange({ value: '', error: '' });
          handleNav();
        }
        if (result.meta.requestStatus === 'fulfilled') {
          navigate('/verify');
        }
        if (result.meta.requestStatus === 'rejected') {
          return setReqErr('Registration request failed, check credentials or try again later');
        }
      });
    }
  };

  const handleChangePass = (e) => {
    setPasswordChange({ value: e.target.value, error: '' });
    setisFilled(true);
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const getStoredEmail = localStorage.getItem('userEmail');

  return (
    <div className={'user-info'}>
      <div className={'main-info'}>
        <div className={'info-title'}>
          <h1>Main information</h1>
          <p>Basic information that identifies you</p>
        </div>
        <InputComponent
          label={'Email address'}
          placeholder={'youremail@mail.com'}
          onChange={handleChangePass}
        />
      </div>
      <form className={'change-pass'}>
        <div className={'change-pass-title'}>
          <h1>Change password</h1>
          <p>Basic information that identifies you</p>
        </div>
        <div className={'inputs-container'}>
          <InputComponent
            label="Current Password"
            value={'aasdasdaq123'}
            onChange={handleChangePass}
          />
          <PasswordInputComponent
            label="New password"
            value={passwordChange.value}
            onChange={handleChangePass}
            placeholder={'Enter actual password'}
          />
          <PasswordInputComponent
            label="New password again"
            value={passwordChange.value}
            onChange={handleChangePass}
            placeholder={'Enter password again'}
          />
          <button className={'save-btn'} type={'submit'}>
            <div>Save</div>
          </button>
        </div>
      </form>
      <div className={'email-auth'}>
        <div className={'auth-title'}>
          <h1>Email auhthentication</h1>
          <p>Basic information that identifies you</p>
        </div>
        <button className={isEnable2FA ? 'disable' : 'enable'} onClick={handleDisableEnableAuth}>
          <div>{isEnable2FA ? 'Disable' : 'Enable'}</div>
        </button>
        {/*{getStoredEmail ? (*/}
        {/*  <div className={'disable2FA'}>*/}
        {/*    <span>*/}
        {/*      <img src={checkCircle} alt={'checkCircle'} />*/}
        {/*      {getStoredEmail}*/}
        {/*    </span>*/}
        {/*    <Button name={'Disable'} onClick={handleDisableAuth} />*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <>*/}
        {/*    {isEnable2FA ? (*/}
        {/*      <form className={'input-email'}>*/}
        {/*        <InputComponent placeholder={'Enter your email'} onChange={handleChangeEmail} />*/}
        {/*        <button className={'cancel'} onClick={toggle2FA}>*/}
        {/*          <div>Cancel</div>*/}
        {/*        </button>*/}
        {/*        <button className={'save'} type={'submit'} onClick={handleSubmitEmail}>*/}
        {/*          <div>Save</div>*/}
        {/*        </button>*/}
        {/*      </form>*/}
        {/*    ) : (*/}
        {/*      <button className={'enable'} onClick={toggle2FA}>*/}
        {/*        <div>Enable</div>*/}
        {/*      </button>*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
    </div>
  );
};
