import './style.scss';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import img1 from '../../assets/images/sideIconDefault.svg';
import img2 from '../../assets/images/audienceicon.png';
import img3 from '../../assets/images/dashboardicon.png';
import img4 from '../../assets/images/campaignsicon.png';
import img5 from '../../assets/images/hashtagicon.png';

import profile from '../../assets/images/user.png';
import settings from '../../assets/images/settings.png';
import bellprofile from '../../assets/images/profbell.png';
import logout from '../../assets/images/proflogout.png';

import img6 from '../../assets/images/audienceicon-white.png';
import img7 from '../../assets/images/dashboardicon-white.png';
import img8 from '../../assets/images/campaignsicon-white.png';
import img9 from '../../assets/images/sideIcon.svg';
import img10 from '../../assets/images/hashtagicon-white.png';

import logo from '../../assets/images/logoRedesigned.png';
import LogOut from '../../assets/images/Linear/Arrows Action/Logout 2.svg';
import wallet from '../../assets/images/Compaigns/Bold/Money/Wallet 2.svg';
import currency from '../../assets/images/Compaigns/Union.svg';
import stars from '../../assets/images/Compaigns/Bold/Weather/Stars.svg';
import userAvatar from '../../assets/images/Compaigns/Ellipse 1853.svg';
import { Logout } from '../logoutPopup/Logout';
export const Sidebar = () => {
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [showSideBar, setShowSideBar] = useState(true);

  const handleOpenPopUp = () => {
    setIsOpenPopUp((prev) => !prev);
  };

  const activePopUpStyle = isOpenPopUp
    ? { border: '1px solid #E8E8E8', borderRadius: '10px' }
    : null;

  const location = useLocation();
  const activePath = location.pathname ? { background: '#7E9D00', borderRadius: '10px' } : null;

  useEffect(() => {
    setShowSideBar(
      location.pathname !== '/email-builder-page' && location.pathname !== '/mail-builder-page',
    );
  }, [location]);

  const navigate = useNavigate();

  const popUp = () => {
    return [
      { text: 'Profile', img: profile },
      { text: 'Settings', img: settings },
      { text: 'Notifications', img: bellprofile },
    ].map((item, i) => {
      return (
        <div className={'popup-box'} key={i}>
          <img src={item.img} alt={'profile-icon'} />
          <span>{item.text}</span>
        </div>
      );
    });
  };

  const handleNav = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('token_2fa');
    navigate('/loginpage', { replace: true });
  };
  return showSideBar ? (
    <div className="sidebar-wrapper">
      <div className="upper-box">
        <div className="side-logo">
          <img src={logo} alt="side-logo" />
        </div>
        <div className={'balance'}>
          <img src={wallet} alt={'sidebar-wallet'} />
          <div className={'count-balance'}>
            <img src={currency} alt={'sidebar-currency'} />
            <span>2800.00</span>
          </div>
        </div>
        {[
          { img: img3, img2: img7, title: 'Dashboard', path: '/dashboard', id: 1 },
          { img: img2, img2: img6, title: 'Audience', path: '/audience', id: 2 },
          // { img: img5, img2: img10, title: 'Tags', path: '/tags', id: 3 },
          { img: img4, img2: img8, title: 'Campaigns', path: '/campaigns', id: 4 },
          { img: img1, img2: img9, title: 'Settings', path: '/settings', id: 5 },
        ].map((item, index) => {
          const isCurrPath = location.pathname.includes(item.path);
          return (
            <Link to={item.path} key={index} style={isCurrPath ? activePath : null}>
              <img
                style={
                  isCurrPath
                    ? { visibility: 'visible' }
                    : { visibility: 'hidden', height: 0, width: 0 }
                }
                src={item.img2}
                alt={''}
              />
              <img
                style={
                  !isCurrPath
                    ? { visibility: 'visible' }
                    : { visibility: 'hidden', height: 0, width: 0 }
                }
                src={item.img}
                alt={''}
              />
              {/*<img countryDropdown={isCurrPath ? item.img2 : item.img} alt='btn-icon'/>*/}
              <span style={isCurrPath ? { color: '#FFFFFF' } : null}>{item.title}</span>
            </Link>
          );
        })}
      </div>
      <div className={'lower-tabs'}>
        <div className={'upgrade-plan'}>
          <img src={stars} alt={'sidebar-stars'} />
          <div className={'sidebar-up-plane'}>
            <span className={'up-plan-span'}>Upgrade plan</span>
            <p className={'up-plan-text'}>Get the most out of Dinomail</p>
          </div>
        </div>
        <Logout onLogout={handleNav} />
        {/*<div className={'user-tab'} onClick={handleOpenPopUp}>*/}
        {/*  <img src={userAvatar} alt={'user-avatar'} className={'user-avatar'} />*/}
        {/*  <span className={'user-name'}>Strange Stegosaurusus</span>*/}
        {/*</div>*/}
      </div>
    </div>
  ) : null;
};
