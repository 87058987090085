import logout from '../../assets/images/Linear/Arrows Action/Logout 2.svg';
import user from '../../assets/images/Linear/Users/User Circle.svg';
import './style.scss';
import React, { useState } from 'react';
import userAvatar from '../../assets/images/Ellipse 1853.svg';

export const Logout = ({ onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLogout = () => {
    onLogout();
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <button onClick={toggleDropdown} className={`dropdown-button ${isOpen ? 'open' : ''}`}>
        <img src={userAvatar} alt={'user-avatar'} />
        <span>Strange Triceratops</span>
      </button>
      {isOpen && (
        <div className="dropdown-content open">
          <div className={'change-acc'}>
            <img src={user} alt={'sidebar-change-acc'} />
            <span>Change account</span>
          </div>
          <div className={'logout'} onClick={handleLogout}>
            <img src={logout} alt={'sidebar-logout'} />
            <span>Sign out</span>
          </div>
          {/*<a href="#" onClick={handleLogout}>*/}
          {/*  Logout*/}
          {/*</a>*/}
          {/*<a href="#">Change Account</a>*/}
        </div>
      )}
    </div>
  );
};
