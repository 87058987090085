import { useContext } from 'react';
import { ReactSortable } from 'react-sortablejs';
import ReactPlayer from 'react-player/lazy';
import { MailContext } from '../../MailBuilderPage';
import { findVideoBuildBlockByID } from '../../../../hooks/mail-buider';
import { DeleteStickyIcon } from '../../../../components/interface/Buttons/DeleteStickyIcon';
import '../styles.css';

export const VideoBuildBlock = ({
  id,
  style,
  isActive,
  onClick,
  showMailPreview,
  onClickRemoveBuildBlock,
}) => {
  const { mailEditorState } = useContext(MailContext);

  const videoBuildBlock = findVideoBuildBlockByID(mailEditorState, id);

  const isActiveBorderOnHover = !showMailPreview ? ' is-hover-block' : '';
  const isActiveBorderOnClick = isActive && !showMailPreview ? ' is-active-content-border' : '';

  return (
    <>
      <div
        id={id}
        className={`video-container${isActiveBorderOnHover}${isActiveBorderOnClick}`}
        onClick={onClick}
        style={style}>
        {videoBuildBlock.map((video) => {
          const isDropVideoHerePlaceholder = video?.url
            ? ''
            : !showMailPreview
            ? ' drop-your-content-here'
            : '';
          return (
            <ReactSortable
              key={video.id}
              list={videoBuildBlock}
              setList={() => {}}
              id={video.id}
              group={{
                name: 'video-list',
                pull: true,
              }}
              sort={true}
              animation={200}
              className={`video-build-block${isDropVideoHerePlaceholder}`}>
              <ReactPlayer
                url={video?.url}
                controls={false}
                className="video-player"
                width="100%"
                height="100%"
              />
            </ReactSortable>
          );
        })}
      </div>
      <DeleteStickyIcon
        showMailPreview={showMailPreview}
        isActive={isActive}
        onClick={onClickRemoveBuildBlock}
      />
    </>
  );
};
