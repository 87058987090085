import { SearchIcon } from '../../../../components/interface/icons/SearchIcon';
import { DinoInput } from '../../../../components/inputComponent/DinoInput';
import { useState } from 'react';

export const ButtonEditor = () => {
  const [search, setSearch] = useState('');

  return (
    <>
      <DinoInput
        leftIcon={<SearchIcon />}
        // rightIcon={<CloseIcon onClick={() => {}} />}
        value={search}
        onChange={({ target: { value } }) => setSearch(value)}
        placeholder="Enter url here"
      />
    </>
  );
};
