import { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { v4 as uuidv4 } from 'uuid';
import { DeleteStickyIcon } from '../../../../components/interface/Buttons/DeleteStickyIcon';
import '../styles.css';

export const GiphyBuildBlock = ({
  id,
  style,
  isActive,
  onClick,
  showMailPreview,
  onClickRemoveBuildBlock,
}) => {
  const [giphyContent, setGiphyContent] = useState([]);

  const isActiveBorderOnHover = !showMailPreview ? ' is-hover-block' : '';
  const isActiveBorderOnClick = isActive && !showMailPreview ? ' is-active-content-border' : '';
  const isDropGiphyHerePlaceholder = giphyContent.length
    ? ''
    : !showMailPreview
    ? ' drop-your-content-here'
    : '';

  return (
    <>
      <div
        id={id}
        className={`${isActiveBorderOnHover}${isActiveBorderOnClick} giphy-container`}
        onClick={onClick}
        style={style}>
        <ReactSortable
          list={giphyContent}
          setList={(newGifState) => {
            setGiphyContent((prevState) => {
              // If there is an existing item at index 0, replace it with the new one
              if (prevState.length > 0) {
                const newGifs = [...prevState];
                newGifs[0] = newGifState[0];
                return newGifs;
              }
              // If no existing items, add the new one
              return newGifState;
            });
          }}
          id={id}
          clone={(item) => ({ ...item, id: uuidv4() })}
          group={{
            name: 'giphy-list',
            put: ['giphy-block'],
          }}
          animation={200}
          className={`giphy-build-block${isDropGiphyHerePlaceholder}`}>
          {giphyContent.map((gif) => (
            <img
              key={gif?.id}
              src={gif?.images.fixed_height_small.webp}
              alt={gif?.title}
              onClick={() => {}}
              className="giphy-image-block"
            />
          ))}
        </ReactSortable>
      </div>
      <DeleteStickyIcon
        showMailPreview={showMailPreview}
        isActive={isActive}
        onClick={onClickRemoveBuildBlock}
      />
    </>
  );
};
