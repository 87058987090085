import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { MailContext } from '../../MailBuilderPage';
import { Accordion } from '../../../../components/accordion/Accordion';
import uploadIcon from '../../../../assets/images/uploadIcon.svg';
import alternateTextIcon from '../../../../assets/images/alternateTextIcon.svg';
import { DinoInput } from '../../../../components/inputComponent/DinoInput';
import { CloseIcon } from '../../../../components/interface/icons/CloseIcon';
import '../styles.css';

export const VideoEditor = () => {
  const [videoContent, setVideoContent] = useState('');
  const { selectedMailEditorBlock, setMailEditorState } = useContext(MailContext);

  const handleVideoContentChange = ({ target: { value } }) => {
    setVideoContent(value);
    setMailEditorState((prevState) => {
      return prevState.map((prevStateElement) => {
        if (prevStateElement?.child) {
          return {
            ...prevStateElement,
            child: prevStateElement.child.map((ch) => {
              if (ch?.children) {
                return {
                  ...ch,
                  children: ch.children.map((grandChild) => {
                    if (grandChild.id === selectedMailEditorBlock.id) {
                      return {
                        ...grandChild,
                        url: value,
                      };
                    }
                    return grandChild;
                  }),
                };
              }
              return ch;
            }),
          };
        }

        if (prevStateElement.id === selectedMailEditorBlock.id) {
          return {
            ...prevStateElement,
            url: value,
          };
        }

        return prevStateElement;
      });
    });
  };

  return (
    <div className="video-editor-container">
      <Accordion icon={uploadIcon} title="Upload Video">
        <p style={{ fontSize: 11 }}>https://vimeo.com/868983629</p>
        <p style={{ fontSize: 11 }}>https://youtu.be/Bv_5Zv5c-Ts</p>
        <DinoInput
          // leftIcon={<SearchIcon />}
          rightIcon={<CloseIcon onClick={() => {}} />}
          value={videoContent}
          onChange={handleVideoContentChange}
          placeholder="Type to search here"
        />
        <ul>
          <li>
            Insert a link to a video from <Link to="https://www.youtube.com">Youtube</Link> or
            <Link to="https://vimeo.com">Vimeo</Link>
          </li>
        </ul>
      </Accordion>
      <Accordion icon={alternateTextIcon} title="Alternate text">
        Its really hot inside Jupiter! No one knows exactly how hot, but scientists think it could
        be about 43,000°F (24,000°C) near Jupiters center, or core.
      </Accordion>
    </div>
  );
};
