import { useState, createContext, useRef, useLayoutEffect } from 'react';
import { useResizeObserver } from '../../hooks/mail-buider';
import { Header } from '../../components/header/Header';
import { SidebarTools } from './mail-sidebar/SidebarTools';
import { MailEditor } from './mail-editor/MailEditor';
import { initEditorMediaQuery, initHtml, initMobileMediaQuery } from './initial.constants';
import './styles.css';

export const MailContext = createContext(null);

export const MailBuilderPage = () => {
  const [activeTab, setActiveTab] = useState('build-blocks-tab');
  const [mailEditorState, setMailEditorState] = useState([]);
  const [selectedBlockID, setSelectedBlockID] = useState(null);
  const [htmlMarkup, setHtmlMarkup] = useState(initHtml);
  const [workspaceWidth, setWorkspaceWidth] = useState();
  const workspaceWidthRef = useRef();
  const [showMailPreview, setShowMailPreview] = useState(false);
  const [mediaQuery, setMediaQuery] = useState(initEditorMediaQuery);

  useResizeObserver(workspaceWidthRef.current, (width) => {
    setWorkspaceWidth(width);
  });

  let selectedMailEditorBlock = {};

  const findBlockById = (block, selectedID) => {
    if (block.id === selectedID) {
      selectedMailEditorBlock = block;
    }

    if (block.child) {
      block.child.forEach((child) => {
        if (child.id === selectedID) {
          selectedMailEditorBlock = child;
        }
        findBlockById(child, selectedID);
      });
    }

    if (block.children) {
      block.children.forEach((grandchild) => {
        if (grandchild.id === selectedID) {
          selectedMailEditorBlock = grandchild;
        }
        findBlockById(grandchild, selectedID);
      });
    }
  };

  mailEditorState?.forEach((editorBlock) => {
    findBlockById(editorBlock, selectedBlockID);
  });

  const values = {
    activeTab,
    setActiveTab,
    mailEditorState,
    setMailEditorState,
    selectedBlockID,
    setSelectedBlockID,
    selectedMailEditorBlock,
    htmlMarkup,
    setHtmlMarkup,
    workspaceWidth,
    showMailPreview,
    setShowMailPreview,
    mediaQuery,
    setMediaQuery,
  };

  const isMobileMode =
    showMailPreview && mediaQuery === initMobileMediaQuery ? ' is-mobile-mode' : '';

  useLayoutEffect(() => {}, []);

  console.log(`%c mail State:`, `color: #f505ed`, mailEditorState);
  return (
    <div className="mail-builder-page">
      <MailContext.Provider value={values}>
        <Header />
        <div className="workspace-container">
          <div className="workspace-tools">{!showMailPreview && <SidebarTools />}</div>
          <div className={`workspace-editor${isMobileMode}`} ref={workspaceWidthRef}>
            <MailEditor />
          </div>
        </div>
      </MailContext.Provider>
    </div>
  );
};
