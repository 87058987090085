import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImageUploaderIcon } from '../../../../components/mail-block-icons/ImageUploaderIcon';
import { DeleteStickyIcon } from '../../../../components/interface/Buttons/DeleteStickyIcon';
import '../styles.css';

export const ImageBuildBlock = ({
  id,
  isActive,
  onClick,
  showMailPreview,
  onClickRemoveBuildBlock,
}) => {
  const [imageFiles, setImageFiles] = useState([]);

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles) => {
      setImageFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const previews = imageFiles.map((file) => (
    <img
      key={file.name}
      src={file.preview}
      alt={file.name}
      className="uploaded-image"
      onLoad={() => URL.revokeObjectURL(file.preview)}
    />
  ));

  useEffect(() => () => imageFiles.forEach((file) => URL.revokeObjectURL(file.preview)), []);

  const isDrag = isDragAccept ? ' is-drag-accept' : '';
  const isReject = isDragReject ? ' is-drag-reject' : '';
  const isActiveBorderOnHover = !showMailPreview ? ' is-hover-block' : '';
  const isActiveBorderOnClick = isActive && !showMailPreview ? ' is-active-content-border' : '';

  return (
    <div
      {...getRootProps({
        className: `tag-content${isActiveBorderOnHover}${isActiveBorderOnClick}${isDrag}${isReject} image-build-block`,
      })}
      id={id}
      onClick={onClick}>
      <input {...getInputProps({ className: 'dropzone-input' })} />
      {imageFiles.length <= 0 ? (
        <>
          {!showMailPreview && (
            <div className="dropzone-container">
              <ImageUploaderIcon />
              <p className="dropzone-placeholder-text">
                Drop the image here or
                <span className="dropzone-placeholder-link" onClick={open}>
                  Browse
                </span>
              </p>
            </div>
          )}
          <DeleteStickyIcon
            showMailPreview={showMailPreview}
            isActive={isActive}
            onClick={onClickRemoveBuildBlock}
          />
        </>
      ) : (
        <>
          <aside className="previews">{previews}</aside>
          <DeleteStickyIcon
            showMailPreview={showMailPreview}
            isActive={isActive}
            onClick={onClickRemoveBuildBlock}
          />
        </>
      )}
    </div>
  );
};
