import { useContext, useRef } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { v4 as uuidv4 } from 'uuid';
import { MailContext } from '../MailBuilderPage';
import { BorderOverChild } from '../../../components/interface/BorderOverChild';
import { initMobileMediaQuery, initDesktopMediaQuery } from '../initial.constants';
import './styles.css';

export const MailEditor = () => {
  const initialRef = useRef(null);

  const {
    mailEditorState,
    setMailEditorState,
    selectedBlockID,
    setSelectedBlockID,
    mediaQuery,
    showMailPreview,
  } = useContext(MailContext);

  const onDragOverStyles = (event) => {
    // Find the element with the class 'on-drag-splitter' within the 'editor-block-container'
    const splitterElement = event.currentTarget.querySelector('.on-drag-splitter');

    // Remove the class if the element is found
    if (splitterElement) {
      splitterElement.classList.remove('on-drag-splitter');
    }

    if (event.target.classList.contains('drop-your-content-here')) {
      event.target.classList.add('highlight-layout-child');
    }
  };

  const onDragLeaveStyles = (event) => event.target.classList.remove('highlight-layout-child');

  const onDropStyles = (event) => event.target.classList.remove('highlight-layout-child');

  const onDragOverHideInitBlock = () =>
    initialRef.current ? (initialRef.current.style.display = 'none') : null;

  const onDuplicateBlock = (id) => {
    setMailEditorState((prevState) => {
      const clickedIndex = prevState.findIndex((block) => block.id === id);

      const duplicatedBlock = {
        ...prevState[clickedIndex],
        id: uuidv4(),
        child: prevState[clickedIndex].child?.map((ch) => {
          if (ch?.children) {
            return {
              ...ch,
              children: ch.children.map((grandChild) => {
                if (grandChild?.id) {
                  return {
                    ...grandChild,
                    id: uuidv4(),
                  };
                }
                return grandChild;
              }),
            };
          }
          return ch;
        }),
      };

      return [
        ...prevState.slice(0, clickedIndex + 1),
        duplicatedBlock,
        ...prevState.slice(clickedIndex + 1),
      ];
    });
  };

  const onDeleteBlock = (id) => {
    setMailEditorState((prevState) => {
      // Find the index of the clicked element
      const deletedIndex = prevState.findIndex((block) => block.id === id);

      // Remove the clicked element from the array. Update the state with the new array
      return [...prevState.slice(0, deletedIndex), ...prevState.slice(deletedIndex + 1)];
    });
  };

  const isPreviewMode = !showMailPreview ? ' is-editor-mode' : '';
  const isPreviewDesktopMode =
    showMailPreview && mediaQuery === initDesktopMediaQuery
      ? ' is-preview-mode is-desktop-mode'
      : '';
  const isPreviewMobileMode =
    showMailPreview && mediaQuery === initMobileMediaQuery ? ' is-preview-mode is-mobile-mode' : '';

  return (
    <ReactSortable
      list={mailEditorState}
      setList={setMailEditorState}
      group={{
        name: 'editor-list',
        pull: true,
        put: ['layout-list', 'orthography-list'],
      }}
      animation={200}
      clone={(item) => ({ ...item, id: uuidv4() })}
      className={`mail-editor${isPreviewMode}${isPreviewDesktopMode}${isPreviewMobileMode}`}>
      {mailEditorState?.length ? (
        mailEditorState?.map((editorBlock) => {
          const Component = editorBlock.content;
          return (
            <div
              key={editorBlock.id}
              onDragOverCapture={onDragOverStyles}
              onDragLeaveCapture={onDragLeaveStyles}
              onDrop={onDropStyles}
              onClick={() => !showMailPreview && setSelectedBlockID(editorBlock.id)}
              className={`editor-block`}>
              <Component
                id={editorBlock.id}
                text={editorBlock.text}
                editorBlock={editorBlock}
                onClick={() => !showMailPreview && setSelectedBlockID(editorBlock.id)}
                isActive={editorBlock.id === selectedBlockID}
                showMailPreview={showMailPreview}
              />
              {!showMailPreview && (
                <BorderOverChild
                  editorBlock={editorBlock}
                  handleDuplicate={onDuplicateBlock}
                  handleDelete={onDeleteBlock}
                />
              )}
            </div>
          );
        })
      ) : (
        <div
          ref={initialRef}
          className="initial-editor-placeholder"
          onDragOver={onDragOverHideInitBlock}>
          <h6 className="placeholder-top-text">Start building your project:</h6>
          <h4 className="placeholder-bottom-text">Drop a paragraph here</h4>
        </div>
      )}
    </ReactSortable>
  );
};
