import './style.scss';
import arrow from '../../../assets/images/whiteArrDown.png';
import search from '../../../assets/images/search.png';
import { useState, useEffect } from 'react';
import plane from '../../../assets/images/plane.png';
import emails from '../../../assets/images/emails.png';
import sms from '../../../assets/images/sms.png';
import journey from '../../../assets/images/journey.png';
import automations from '../../../assets/images/automations.png';
import arrUp from '../../../assets/images/whiteArrUp.png';
import deleted from '../../../assets/images/deleteTag.png';
import graph from '../../../assets/images/Graph.png';
import TestHeader from '../../../components/header/TestHeader';
import startCampaign from '../../../assets/images/Compaigns/Frame 981318.svg';
import { SubHeader } from './subHeader/SubHeaderCampaign';
import { FILTERED_CARDS, TAB_ITEMS } from '../../../constants/campaignsConstants';
import notFound from '../../../assets/images/Compaigns/Frame 981286.svg';
import { CampaignModal } from './createCampaignModal/CampaignModal';

export const Campaigns = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isShowContent, setisShowContent] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [isOpenSubheader, setIsOpenSubheader] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [hasSearchResults, setHasSearchResults] = useState(true);
  const [filterCards, setFilteredCards] = useState(FILTERED_CARDS);

  const handleOpenModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  const handleOpenSubheader = () => {
    setIsOpenSubheader((prev) => !prev);
  };

  const handleRowHover = (event, rowIndex) => setHoveredIndex(rowIndex);
  const handleRowHoverLeave = (event, rowIndex) => setHoveredIndex(null);

  const handleShowContent = () => {
    setisShowContent(true);
  };

  const createCampaign = (
    <div className="btn-wrapper">
      <div onClick={handleOpenSubheader} className={'create-camp-btn'}>
        <span>Create new campaign</span>
        <img src={isOpenSubheader ? arrUp : arrow} className={'camp-arr'} />
      </div>
    </div>
  );

  const placeholder = (
    <div className={'placeholder-wrapper'}>
      <img src={startCampaign} alt={'start-campaign'} />
      <div className={'placeholder-des'}>
        <h2 className={'place-title'}>Start your new campaign!</h2>
        <p className={'place-text'}>
          Create an effective email campaign to connect with your audience, build brand awareness,
          and drive more sales.
        </p>
      </div>
      <button onClick={handleShowContent} className={'create-btn-holder'}>
        <span>Create campaign</span>
      </button>
    </div>
  );

  const filteredCards = filterCards.filter((card) => {
    if (selectedStatus === 'All' && !searchQuery) {
      return true;
    }

    const matchesStatus = selectedStatus === 'All' || card.status === selectedStatus;
    const matchesSearch = card.title.toLowerCase().includes(searchQuery.toLowerCase());

    return matchesStatus && matchesSearch;
  });
  const handleDeleteCard = (index) => {
    const updatedFilteredCards = [...filterCards];
    updatedFilteredCards.splice(index, 1);
    setFilteredCards(updatedFilteredCards);
  };

  useEffect(() => {
    setHasSearchResults(filteredCards.length > 0);

    const el = document.getElementsByClassName('campaigns-box');
  }, [filteredCards]);
  return (
    <div className="capmaigns-wrapper">
      <TestHeader
        icon={plane}
        description={'Campaigns'}
        content={isShowContent ? createCampaign : null}
      />
      {isOpenModal && <CampaignModal closeModal={handleOpenModal} />}
      {isOpenSubheader ? <SubHeader handleOpenModal={handleOpenModal} /> : null}
      {isShowContent ? (
        <div className={'campaigns-box'} style={isOpenSubheader ? { marginTop: '18vh' } : null}>
          <div className="c-title">
            <h1>Campaigns</h1>
            <p>
              Email marketing feature for creating and sending newsletters with email templates,
              email addresses, send time settings, and results analytics.
            </p>
          </div>
          <div className="inner-box">
            <div className="tabs">
              <h4>View by Status</h4>
              <div className="upper-container">
                {Object.entries(TAB_ITEMS).map(([category, items]) => (
                  <div key={category}>
                    {items.map((item, index) => (
                      <div
                        className="link"
                        key={index}
                        onClick={() => setSelectedStatus(item.text)}>
                        <img src={item.img} alt={item.text} />
                        <span className="text">{item.text}</span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {[
                { img: sms, text: 'SMS', des: 'Soon' },
                { img: journey, text: 'Journey', des: 'Soon' },
                { img: automations, text: 'AlAutomations', des: 'Soon' },
              ].map((item, index) => {
                return (
                  <div className="link-s" key={index}>
                    <img src={item.img} />
                    <span className="text">{item.text}</span>
                    <div className="status">
                      <span>{item.des}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="cards">
              <div className="search-input">
                <input
                  placeholder="Email, name, address, etc..."
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <img src={search} />
              </div>
              {hasSearchResults ? (
                filteredCards
                  .filter((card) => {
                    if (selectedStatus == 'All') {
                      return true;
                    }
                    return card.status === selectedStatus;
                  })
                  .map((item, index) => {
                    const isDeleted = item.isDeleted;
                    return (
                      <div
                        className={`card ${isDeleted ? 'fade-out' : ''}`}
                        key={index}
                        onMouseEnter={(e) => handleRowHover(e, index)}
                        onMouseLeave={(e) => handleRowHoverLeave(e, index)}>
                        <div
                          id="buttons"
                          style={
                            index == hoveredIndex
                              ? { display: 'flex', position: 'absolute' }
                              : { display: 'none' }
                          }
                          className="button-wrapper">
                          <img
                            src={deleted}
                            onClick={() => handleDeleteCard(index)}
                            style={{
                              width: '32px',
                              height: '32px',
                              marginRight: '10px',
                              cursor: 'pointer',
                            }}
                          />
                          {/*<img*/}
                          {/*  src={graph}*/}
                          {/*  style={{ width: '32px', height: '32px', cursor: 'pointer' }}*/}
                          {/*/>*/}
                        </div>
                        <img src={item.img} alt="cardImg" />
                        <div className="card-content">
                          <div className="card-title">
                            <h5>{item.title}</h5>
                            {
                              <div className="tab-select" style={{ background: item.color }}>
                                <span>{item.status}</span>
                              </div>
                            }
                          </div>
                          <div className="status">
                            <span>{item.status}</span>
                          </div>
                          <span className="type">{item.type}</span>
                          <span className="des">{item.des}</span>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="no-results-image">
                  <img src={notFound} alt="No results" />
                  <span>Nothing found</span>
                  <p>We couldn’t find what you were looking for</p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        placeholder
      )}
    </div>
  );
};
