import { Sidebar } from '../../components/sidebar/Sidebar';
import './style.scss';
import { Navbar } from 'react-bootstrap';

export const HomePage = () => {
  return (
    <div className={'homepage'}>
      <Navbar />
      <Sidebar />
    </div>
  );
};
