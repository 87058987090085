import html from '../../assets/images/Compaigns/Frame 980935.svg';
import text from '../../assets/images/Compaigns/Frame 980935.svg';
import custom from '../../assets/images/Compaigns/Frame 980935.svg';
import star from '../../assets/images/Compaigns/Bold/Like/Star.svg';
import emails from '../../assets/images/emails.png';
import all from '../../assets/images/all.png';
import ongoing from '../../assets/images/ongoing.png';
import completed from '../../assets/images/completed.png';
import drafts from '../../assets/images/drafts.png';
export const MODAL_CARDS = [
  {
    img: html,
    img2: star,
    title: 'Upload HTML',
    background: 'rgba(126, 157, 0, 0.10)',
    hover: '#7E9D00',
  },
  {
    img: text,
    title: 'Manual text input',
    background: 'rgba(102, 109, 165, 0.10)',
    hover: '#666DA5',
  },
  {
    img: custom,
    title: 'Custom design',
    background: 'rgba(211, 103, 0, 0.10)',
    hover: '#D36700',
    path: '/mail-builder-page',
  },
];
export const TAB_ITEMS = {
  email: [
    { img: all, text: 'All' },
    { img: ongoing, text: 'Ongoing' },
    { img: completed, text: 'Completed' },
    { img: drafts, text: 'Draft' },
  ],
  // Add other categories if needed
};

export const FILTERED_CARDS = [
  {
    img: emails,
    title: 'Welcome message',
    status: 'Draft',
    color: '#a5a5a5',
    type: 'Regular',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'Welcome message',
    status: 'Draft',
    color: '#a5a5a5',
    type: 'Regular',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'Welcome message',
    status: 'Draft',
    color: '#a5a5a5',
    type: 'Regular',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'Promocode gift',
    status: 'Ongoing',
    type: 'Event',
    color: '#3F93F7',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'Promocode gift',
    status: 'Ongoing',
    type: 'Event',
    color: '#3F93F7',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'Promocode gift',
    status: 'Ongoing',
    type: 'Event',
    color: '#3F93F7',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'Promocode gift',
    status: 'Ongoing',
    type: 'Event',
    color: '#3F93F7',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'The weekly drop',
    status: 'Completed',
    color: '#1BBDA0',
    type: 'Event',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'The weekly drop',
    status: 'Completed',
    color: '#1BBDA0',
    type: 'Event',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'The weekly drop',
    status: 'Scheduled',
    color: '#EDB833',
    type: 'Event',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'The weekly drop',
    status: 'Scheduled',
    color: '#EDB833',
    type: 'Event',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
  {
    img: emails,
    title: 'The weekly drop',
    status: 'Scheduled',
    color: '#EDB833',
    type: 'Event',
    des: 'Edited st, october 4th 10:11 AM by You',
  },
];
