import React, { useRef } from 'react';
import copy from '../../../../../../../assets/images/Linear/Essentional, UI/Copy.svg';
import './style.scss';
// Child Input component
const InputComponent = ({ placeholderText, labelText }) => {
  const inputRef = useRef(null);

  const copyText = () => {
    const inputElement = inputRef.current;

    inputElement.select();
    inputElement.setSelectionRange(0, 99999); // For mobile devices

    document.execCommand('copy');

    inputElement.setSelectionRange(0, 0);

    alert('Text copied: ' + inputElement.value);
  };

  return (
    <div className={'input-wrapper'}>
      <label htmlFor="textInput">{labelText}:</label>
      <input
        type="text"
        id="textInput"
        value={placeholderText}
        readOnly
        ref={inputRef}
        className={'custom-copy-input'}
      />
      <img onClick={copyText} src={copy} />
    </div>
  );
};

export default InputComponent;
