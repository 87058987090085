import InputComponent from '../../inputComponent/InputComponent';
import { useEffect, useRef, useState } from 'react';
import { ReactCountryDropdown } from '../../countryDropdown';
export const CompanyInfo = () => {
  const parentRef = useRef(null);

  useEffect(() => {
    const modalElements = parentRef.current.getElementsByClassName('styles_dropdown_items_wrapper');
    if (modalElements.length > 0) {
      parentRef.current.style.height = '600px';
      parentRef.current.style.alignItems = 'flex-start';
    }
  }, []);

  return (
    <div className={'company-info'}>
      <div className={'company-name'}>
        <div className={'name-title'}>
          <h1>Company name</h1>
          <p>Basic information that identifies you</p>
        </div>
        <InputComponent placeholder={'Dinomail'} />
      </div>
      <div className={'company-des'}>
        <div className={'name-title'}>
          <h1>Company description</h1>
          <p>This information will be used to generate content by artificial intelligence</p>
        </div>
        <textarea />
      </div>
      <div className={'target-audience-des'}>
        <div className={'name-title'}>
          <h1>Description of the target audience</h1>
          <p>
            This information is necessary for a more accurate understanding of the specifics of
            mailing recipients by the artificial interject.
          </p>
        </div>
        <textarea />
      </div>
      <div className={'company-address'} ref={parentRef}>
        <div className={'name-title'}>
          <h1>Description of the target audience</h1>
          <p>
            May be necessary to comply with laws on the protection of personal data and consumer
            rights and ensuring the legality of the mailing
          </p>
        </div>
        <div className={'dropdowns'}>
          <ReactCountryDropdown />
          <InputComponent />
          <InputComponent />
          <InputComponent />
        </div>
      </div>
    </div>
  );
};
