import { useNavigate } from 'react-router-dom';
import authImg from '../../assets/images/auth-picture.jpg';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, verifyUser } from '../../store/userSlice';
import './style.scss';

export const ConfirmationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNav = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    const token = window.location.href.split('/').pop();
    console.log(token, 'verify token');
    if (token) {
      dispatch(verifyUser(token)).then((result) => {
        console.log(result, 'verify handler');
      });
    }
  }, []);

  return (
    <div className={'confirmated'}>
      <div className={'text-wrapper'}>
        <h1>Email confirmed successfully</h1>
        <p>Now you can discover all the features and benefits of our wonderful Dinomail</p>
        <button onClick={handleNav}>
          <div>Let&apos;s enjoy</div>
        </button>
      </div>
      <div className={'img-wrapper'}>
        <img src={authImg} className={'confirmated-img'} />
      </div>
    </div>
  );
};
