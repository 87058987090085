import React from 'react';
import { useState } from 'react';
import './style.scss';

const DashboardTabs = ({ activeTab, onTabClick, tabContent }) => {
  return (
    <div className="tab-dashboard">
      <div className="tabs">
        <div
          className={`tab ${activeTab === 'campaigns' ? 'active' : ''}`}
          onClick={() => onTabClick('campaigns')}>
          Campaigns
        </div>
        <div
          className={`tab ${activeTab === 'overall' ? 'active' : ''}`}
          onClick={() => onTabClick('overall')}>
          Overall
        </div>
      </div>
    </div>
  );
};

export default DashboardTabs;
