import { useContext } from 'react';
import DOMPurify from 'dompurify';
import { MailContext } from '../../MailBuilderPage';
import { DeleteStickyIcon } from '../../../../components/interface/Buttons/DeleteStickyIcon';

export const HtmlBuildBlock = ({
  id,
  style,
  isActive,
  onClick,
  showMailPreview,
  onClickRemoveBuildBlock,
}) => {
  const { htmlMarkup, selectedMailEditorBlock } = useContext(MailContext);

  const isActiveBorderOnHover = !showMailPreview ? ' is-hover-block' : '';
  const isActiveBorderOnClick = isActive && !showMailPreview ? ' is-active-content-border' : '';

  console.log('Block', selectedMailEditorBlock, htmlMarkup);
  return (
    <div
      id={id}
      onClick={onClick}
      className={`tag-content${isActiveBorderOnHover}${isActiveBorderOnClick} html-build-block`}>
      <code
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(htmlMarkup),
        }}
      />
      <DeleteStickyIcon
        showMailPreview={showMailPreview}
        isActive={isActive}
        onClick={onClickRemoveBuildBlock}
      />
    </div>
  );
};
