import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
export const registerUser = createAsyncThunk('user/registerUser', async (userRegisterData) => {
  try {
    const req = await fetch(`/auth/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userRegisterData),
      credentials: 'include',
    });
    if (req.status !== 200) {
      throw new Error(`Request failed with status code ${req.status}, ${await req.text()}`);
    }
    return true;
  } catch (error) {
    console.error(`Register error: ${error}`);
    return false;
  }
});
export const verifyUser = createAsyncThunk('user/verifyUser', async (token) => {
  try {
    const req = await fetch(`/auth/confirm/registration/${token}`, { credentials: 'include' });
    const res = await req.json();
    localStorage.setItem('access_token', res.access_token);
    if (res.refresh_token) {
      localStorage.setItem('refresh_token', res.refresh_token);
    }
    console.log('access token: ', res.access_token);
    return res;
  } catch (error) {
    console.log(`Register verify error: ${error}`);
    return null;
  }
});

export const loginUser = createAsyncThunk('user/loginUser', async (userCredentials) => {
  try {
    const req = await fetch(`/auth/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userCredentials),
      credentials: 'include',
    });
    if (req.status !== 200) {
      throw new Error(`Request failed with status code ${req.status}, ${await req.text()}`);
    }
    const res = await req.json();
    if (res.token_2fa) {
      localStorage.setItem('token_2fa', res.token_2fa);
      console.log('token 2fa: ', res.token_2fa);
    } else {
      localStorage.setItem('access_token', res.access_token);
      if (res.refresh_token) {
        localStorage.setItem('refresh_token', res.refresh_token);
      }
      console.log('access token: ', res.access_token);
    }
    return res;
  } catch (error) {
    console.error('Error in login request', error);
    return null;
  }
});

export const sendCode2FA = createAsyncThunk('user/sendCode2FA', async () => {
  const token_2fa = localStorage.getItem('token_2fa');
  try {
    const req = await fetch('/auth/send-code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token_2fa: token_2fa,
      }),
      credentials: 'include',
    });
    if (req.status !== 200) {
      throw new Error(`Request failed with status code ${req.status}, ${await req.text()}`);
    }
    return true;
  } catch (error) {
    console.error('Error in 2FA login request', error);
    return false;
  }
});

export const verifyCode2FA = createAsyncThunk('user/verifyCode2FA', async (code) => {
  const token2FA = localStorage.getItem('token_2fa');
  try {
    const req = await fetch(`/auth/sign-2fa?code=${code}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token_2fa: token2FA }),
      credentials: 'include',
    });
    if (req.status !== 200) {
      throw new Error(`Request failed with status code ${req.status}, ${await req.text()}`);
    }
    const res = await req.json();
    localStorage.setItem('access_token', res.access_token);
    if (res.refresh_token) {
      localStorage.setItem('refresh_token', res.refresh_token);
    }
    console.log('access token: ', res.access_token);
    return res;
  } catch (error) {
    console.log(error, 'catch');
    return null;
  }
});

export const validateAccessToken = () => (dispatch, getState) => {
  const userToken = localStorage.getItem('user');
  const TwoFactorToken = localStorage.getItem('token_2fa');
  if (userToken || TwoFactorToken) {
    try {
      dispatch(userSlice.actions.checkAuth());
    } catch (error) {
      localStorage.removeItem('user');
      dispatch(userSlice.actions.checkAuth());
    }
  }
};
export const setToken2FA = createAsyncThunk('user/setToken2FA', async (token2FA) => {
  return token2FA;
});

export const setSubscribersData = (data) => ({
  type: 'user/setSubscribersData',
  payload: data,
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLogged: false,
    loading: true,
    user: null,
    error: null,
    email: '',
    token2FA: null,
    subscribersData: [],
    selectedOption: 'Year',
    activeTab: 'emails',
  },
  reducers: {
    checkAuth: (state) => {
      const userToken = localStorage.getItem('user');
      const TwoFactorToken = localStorage.getItem('token_2fa');
      if (userToken || TwoFactorToken) {
        state.isLogged = true;
        state.token2FA = TwoFactorToken;
      }
    },
    toggleTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setSubscribersData: (state, action) => {
      state.subscribersData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(loginUser.pending, (state) => {
      //   state.loading = true;
      //   state.user = null;
      //   state.error = null;
      //   state.isLogged = false;
      // })
      // .addCase(loginUser.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.isLogged = true;
      //   state.user = action.payload;
      //   console.log(action.payload, 'action');
      //   state.error = null;
      // })
      // .addCase(loginUser.rejected, (state, action) => {
      //   state.loading = false;
      //   state.user = null;
      //   state.isLogged = false;
      //   if (action.error.message === 'Request failed with status code 401') {
      //     state.error = 'Access Denied! Invalid credentials.';
      //   }
      //   state.error = null;
      // })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.user = null;
        state.isLogged = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.isLogged = true;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isLogged = false;
        if (action.error.message === 'Request failed with status code 401') {
          state.error = 'Access Denied! Invalid credentials.';
        }
        state.error = null;
      })
      .addCase(sendCode2FA.fulfilled, (state, action) => {
        state.token2FA = action.payload;
      })
      .addCase(sendCode2FA.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(verifyCode2FA.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyCode2FA.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the result of code verification as needed
        console.log(action.payload);
      })
      .addCase(verifyCode2FA.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userSlice.actions.checkAuth, (state) => {
        const userToken = localStorage.getItem('user');
        if (userToken) {
          state.isLogged = true;
        } else {
          state.isLogged = false;
        }
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        console.log(action.error.message);
        if (action.error.message === 'Request failed with status code 401') {
          state.error = 'Something goes wrong with register';
        }
        state.error = null;
      });
  },
});

export const { checkAuth, setEmail, clearEmail, setSelectedOption, toggleTab } = userSlice.actions;

export default userSlice.reducer;
