import { useState } from 'react';
import { DomainAuth } from '../../../pages/homePage/settings/domainAuth/DomainAuth';
import { useNavigate } from 'react-router-dom';

export const DomainInfo = () => {
  const navigate = useNavigate();
  const handleClickVerify = () => {
    navigate('/settings/domain_auth');
  };
  return (
    <div className={'domain-wrapper'}>
      <div className={'domain-title'}>
        <h1>Email domains</h1>
        <p>
          Your email domains control how your emails are sent through Mailchimp. Verifying and
          authenticating your domain helps your hard work get to your customers&apos; inboxes.
        </p>
      </div>
      <button onClick={handleClickVerify}>
        <div>Add & Verify Domain</div>
      </button>
    </div>
  );
};
