import './style.scss';
import addSquare from '../../../../assets/images/addSquare.svg';
import usersGroupRounded from '../../../../assets/images/usersGroupRounded.svg';
import pieChart from '../../../../assets/images/pieChart2.svg';
import pen from '../../../../assets/images/pen.svg';
import React, { useState } from 'react';
import { Modal } from '../../../../components/modal/Modal';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

const mockDate = format(new Date(2024, 0, 26), 'MMM d, yyyy');
const generateNewDate = () => format(new Date(Date.now()), 'MMM d, yyyy');

const mockList = [
  {
    id: uuidv4(),
    name: 'Core list',
    contacts: '142 032',
    segments: '13',
    created: mockDate,
    modified: mockDate,
  },
];

export const AudienceList = () => {
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [list, setList] = useState(mockList);
  const [name, setName] = useState('');

  const toggleCreateModal = () => {
    setName('');
    setCreateModal(!createModal);
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  const openEditModal = (name) => () => {
    setEditModal(true);
  };

  const onChange = (event) => {
    const value = event.target.value;
    setName(value);
  };

  const onCreate = () => {
    setList((prevState) => {
      return [
        ...prevState,
        {
          id: uuidv4(),
          contacts: 0,
          segments: 0,
          created: generateNewDate(),
          modified: generateNewDate(),
          name,
        },
      ];
    });
    setName('');
    toggleCreateModal();
  };
  const onEdit = (id, name) => {
    setList((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return { ...item, name, modified: generateNewDate() };
        }
        return item;
      });
    });
  };

  return (
    <>
      <div className="audience-list">
        {list.map((item) => {
          return (
            <AudienceListItem
              key={item.id}
              item={item}
              modal={editModal}
              openModal={openEditModal(item.name)}
              closeModal={closeEditModal}
              onEdit={onEdit}
            />
          );
        })}
        <div className="audience-add-new-list" onClick={toggleCreateModal}>
          <img src={addSquare} alt="" />
          <span>Add new list</span>
        </div>
      </div>
      <Modal isOpen={createModal}>
        <div className="audience-list-modal-content">
          <div className="audience-list-modal-header">
            <span>Create new list</span>
          </div>
          <div className="audience-list-modal-body">
            <span>List name</span>
            <input
              type="text"
              name="name"
              placeholder="Enter list name"
              value={name}
              onChange={onChange}
            />
          </div>
          <div className="audience-list-modal-buttons">
            <button onClick={toggleCreateModal} className="cancel-btn">
              <span>Cancel</span>
            </button>
            <button className="save-btn" onClick={onCreate}>
              <span>Create</span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const AudienceListItem = ({ item, modal, openModal, closeModal, onEdit }) => {
  const { id, name, contacts, segments, created, modified } = item;

  const [newName, setNewName] = useState(name);

  const onChange = (event) => {
    const value = event.target.value;
    console.log({ target: event.target });
    setNewName(value);
  };

  const onOpen = () => {
    setNewName(name);
    openModal();
  };

  const onClose = () => {
    setNewName('');
    closeModal();
  };

  const onSave = () => {
    onEdit(id, newName);
    onClose();
  };

  return (
    <>
      <div className="audience-list-item">
        <div className="audience-list-item_title">
          <span>{name}</span>
          <img src={pen} alt="" onClick={onOpen} />
        </div>
        <div className="audience-list-item_stats">
          <div className="audience-list-item_stats__item">
            <img src={usersGroupRounded} alt="" />
            <span>{contacts}</span>
          </div>
          <div className="audience-list-item_stats__item">
            <img src={pieChart} alt="" />
            <span>{segments}</span>
          </div>
        </div>
        <div className="audience-list-item_date">
          <span>
            Created: <strong>{created}</strong>
          </span>
        </div>
        <div className="audience-list-item_date">
          <span>
            Edited: <strong>{modified}</strong>
          </span>
        </div>
      </div>
      <Modal isOpen={modal}>
        <div className="audience-list-modal-content">
          <div className="audience-list-modal-header">
            <span>Edit list name</span>
          </div>
          <div className="audience-list-modal-body">
            <span>List name</span>
            <input type="text" name="name" value={newName} onChange={onChange} />
          </div>
          <div className="audience-list-modal-buttons">
            <button onClick={onClose} className="cancel-btn">
              <span>Cancel</span>
            </button>
            <button className="save-btn" onClick={onSave}>
              <span>Save</span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
