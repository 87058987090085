// TabComponent.js
import React, { useState } from 'react';
import { UserInfo } from './userInfo/UserInfo';
import { CompanyInfo } from './companyInfo/CompanyInfo';
import { DomainInfo } from './domain/DomainInfo';
const TabComponent = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="tab-container">
      <div className={'tabs'}>
        <div className={`tab ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
          User info
        </div>
        <div className={`tab ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>
          Company information
        </div>
        <div className={`tab ${activeTab === 3 ? 'active' : ''}`} onClick={() => handleTabClick(3)}>
          Domain
        </div>
      </div>
      <div className="tab-content">
        {activeTab === 1 && <UserInfo />}
        {activeTab === 2 && <CompanyInfo />}
        {activeTab === 3 && <DomainInfo />}
      </div>
    </div>
  );
};

export default TabComponent;
