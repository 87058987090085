import React, { useEffect, useState } from 'react';
import { Button } from './nextButton';
import { SuccessStatus } from './SuccessStatus';
import { PendingStatus } from './PendingStatus';

export const FourthForm = ({ handleNextStep, onStatusChange }) => {
  const [status, setStatus] = useState('default'); // 'default', 'pending', 'success'

  const handleCheckStatus = () => {
    setStatus('pending');
    setTimeout(() => {
      setStatus('success');
      onStatusChange('success');
    }, 2000);
  };

  const renderContent = () => {
    switch (status) {
      case 'pending':
        return <PendingStatus />;
      case 'success':
        return <SuccessStatus />;
      default:
        return (
          <div className={'fourth-form-content'}>
            <p className={'title-form'}>Wait for Dinomail to check the records.</p>
            <div className={'form-text'}>
              It can take Dinomail some time to validate that the records were created correctly, so
              close this window and go enjoy a cup of coffee. (Though it could take up to 48 hours.
              Maybe brew a pot.) <br />
              <br />
              We&apos;ll send you an email to let you know if everything is good or if we
              couldn&apos;t validate your records. Don&apos;t worry, we&apos;ll take you through how
              to fix any errors. And you can keep sending emails though Dinomail while you wait.
            </div>
            <Button name={'Check status'} onClick={handleCheckStatus} />
          </div>
        );
    }
  };

  return renderContent();
};
