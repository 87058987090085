import './styles.css';

export const TextIconButton = ({ icon, onClick, text }) => {
  return (
    <div className="btn-preview-wrapper" onClick={onClick}>
      <span className="btn-preview-text">{text}</span>
      {icon}
    </div>
  );
};
