import React, { useState } from 'react';
import dropdown from '../../assets/images/dropdownActive.png';
import dropdownblack from '../../assets/images/blackdropdown.png';
const CustomDropdown = ({ label, options, onSelect, isSelectedItem, placeholder }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
    setIsSelected(true);
  };

  return (
    <div className="custom-dropdown">
      {label && <label className="dropdown-label">{label}</label>}
      <div className="selected-option" onClick={() => setIsOpen(!isOpen)}>
        {selectedOption || <div className={'placeholder'}>{placeholder}</div>}
        <img src={isOpen ? dropdownblack : dropdown} alt={'domain-arrow'} />
      </div>
      {isOpen && (
        <ul className="options">
          {options.map((option) => (
            <li key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
