import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MailContext } from '../../pages/mail-builder-page/MailBuilderPage';
import plane from '../../assets/images/plane.png';
import { DesktopIcon } from '../interface/Buttons/DesktopIcon';
import { MobileIcon } from '../interface/Buttons/MobileIcon';
import { TextIconButton } from '../interface/Buttons/TextIconButton';
import './styles.css';
import {
  initDesktopMediaQuery,
  initMobileMediaQuery,
} from '../../pages/mail-builder-page/initial.constants';
import { PenEditIcon } from '../interface/Buttons/PenEditIcon';
import { EnvelopSendIcon } from '../interface/Buttons/EnvelopSendIcon';
import { UnDoIcon } from '../interface/Buttons/UnDoIcon';
import { ReDoIcon } from '../interface/Buttons/ReDoIcon';
import { PreviewIcon } from '../interface/Buttons/PreviewIcon';
import { FileIcon } from '../interface/Buttons/FileIcon';
import { SendMailModal } from '../modals/SendMailModal';

export const Header = () => {
  const [isOpenSendMailModal, setIsOpenSendMailModal] = useState(false);

  const { showMailPreview, setShowMailPreview, mediaQuery, setMediaQuery } =
    useContext(MailContext);
  const navigate = useNavigate();

  const handleNavBack = () => {
    navigate('/tags/', { replace: true });
  };

  const isDesktopMode = mediaQuery === initDesktopMediaQuery ? ' is-desktop' : '';
  const isMobileMode = mediaQuery === initMobileMediaQuery ? ' is-mobile' : '';

  return (
    <div className="header">
      {!showMailPreview ? (
        <div className="content-box">
          <div className="route-status">
            <img src={plane} alt="" />
            <div className="manual-routes">
              <span onClick={handleNavBack}> Campaigns /</span>
              <span onClick={handleNavBack}> Create new campaign /</span>
              <p>Design email</p>
            </div>
          </div>
          <div className="header-btns-group">
            <div className="undo-redo-group">
              <UnDoIcon onClick={() => {}} />
              <ReDoIcon onClick={() => {}} />
            </div>
            <PreviewIcon
              onClick={() => {
                setShowMailPreview(!showMailPreview);
                !showMailPreview
                  ? setMediaQuery(initDesktopMediaQuery)
                  : setMediaQuery(initMobileMediaQuery);
              }}
            />
            <TextIconButton onClick={() => {}} text="15 Kb" icon={<FileIcon />} />
            <button className={'save-btn'}>
              <span>Save</span>
            </button>
          </div>
        </div>
      ) : (
        <div className="header-icon-btn-container">
          <div className="media-query-btn-wrapper">
            <DesktopIcon
              className={`${isDesktopMode}`}
              onClick={() => setMediaQuery(initDesktopMediaQuery)}
            />
            <MobileIcon
              className={`${isMobileMode}`}
              onClick={() => setMediaQuery(initMobileMediaQuery)}
            />
          </div>
          <div className="preview-btn-wrapper">
            <TextIconButton
              onClick={() => setShowMailPreview(!showMailPreview)}
              text="Back to edit"
              icon={<PenEditIcon />}
            />
            <TextIconButton
              text="Send test email"
              icon={<EnvelopSendIcon />}
              onClick={() => setIsOpenSendMailModal(!isOpenSendMailModal)}
            />
          </div>
          <SendMailModal
            isOpenModal={isOpenSendMailModal}
            onClose={() => setIsOpenSendMailModal(!isOpenSendMailModal)}
          />
        </div>
      )}
    </div>
  );
};
