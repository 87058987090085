import { v4 as uuidv4 } from 'uuid';
import { ThirtySeventyBlockIcon } from '../../components/mail-block-icons/ThirtySeventyBlockIcon';
import { ThirtyThreeBlockIcon } from '../../components/mail-block-icons/ThirtyThreeBlockIcon';
import { TwentyFiveByFourBlockIcon } from '../../components/mail-block-icons/TwentyFiveByFourBlockIcon';
import { SixteenSixBlockIcon } from '../../components/mail-block-icons/SyxteenBlockIcon';
import { ParagraphIcon } from '../../components/mail-block-icons/ParagraphIcon';
import { HeaderIcon } from '../../components/mail-block-icons/HeaderIcon';
import { ListIcon } from '../../components/mail-block-icons/ListIcon';
import { ButtonIcon } from '../../components/mail-block-icons/ButtonIcon';
import { DividerIcon } from '../../components/mail-block-icons/DividerIcon';
import { ImageIcon } from '../../components/mail-block-icons/ImageIcon';
import { SpacingIcon } from '../../components/mail-block-icons/SpacingIcon';
import { SocialIcon } from '../../components/mail-block-icons/SocialIcon';
import { HtmlIcon } from '../../components/mail-block-icons/HtmlIcon';
import { FooterIcon } from '../../components/mail-block-icons/FooterIcon';
import { VideoIcon } from '../../components/mail-block-icons/VideoIcon';
import { GiphyIcon } from '../../components/mail-block-icons/GiphyIcon';
import { FortySixtyBlockIcon } from '../../components/mail-block-icons/FortySixtyBlockIcon';
import { FiftyFiftyBlockIcon } from '../../components/mail-block-icons/FiftyFiftyBlockIcon';
import { SixtyFortyBlockIcon } from '../../components/mail-block-icons/SixtyFortyBlockIcon';
import { SeventyThirtyBlockIcon } from '../../components/mail-block-icons/SeventyThirtyBlockIcon';
import { TwentyFiveFiftyBlockIcon } from '../../components/mail-block-icons/TwentyFiveFiftyBlockIcon';
import { TwentyFiveFiftyTwentyFiveBlockIcon } from '../../components/mail-block-icons/TwentyFiveFiftyTwentyFiveBlockIcon';
import { FiftyTwentyFiveBlockIcon } from '../../components/mail-block-icons/FiftyTwentyFiveBlockIcon';
import { ParagraphBuildBlock } from './mail-sidebar/text-tabs/ParagraphBuildBlock';
import { HeadingBuildBlock } from './mail-sidebar/text-tabs/HeadingBuildBlock';
import { ListBuildBlock } from './mail-sidebar/text-tabs/ListBuildBlock';
import { ButtonBuildBlock } from './mail-sidebar/button-tabs/ButtonBuildBlock';
import { ImageBuildBlock } from './mail-sidebar/image-tabs/ImageBuildBlock';
import { SpacingBuildBlock } from './mail-sidebar/spacing-tabs/SpacingBuildBlock';
import { DividerBuildBlock } from './mail-sidebar/divider-tabs/DividerBuildBlock';
import { LayoutContainerBlock } from './mail-editor/LayoutContainerBlock';
import { SocialBuildBlock } from './mail-sidebar/social-tabs/SocialBuildBlock';
import { HtmlBuildBlock } from './mail-sidebar/html-tabs/HtmlBuildBlock';
import { FooterBuildBlock } from './mail-sidebar/footer-tabs/FooterBuildBlock';
import { VideoBuildBlock } from './mail-sidebar/video-tabs/VideoBuildBlock';
import { GiphyBuildBlock } from './mail-sidebar/giphy-tabs/GiphyBuildBlock';

export const buildingBlocksInitialState = [
  {
    id: uuidv4(),
    icon: ParagraphIcon,
    content: ParagraphBuildBlock,
    text: 'This is a paragraph.',
    title: 'Paragraph',
    tabs: 'orthographyTabs',
  },
  {
    id: uuidv4(),
    icon: HeaderIcon,
    content: HeadingBuildBlock,
    text: 'This is a heading.',
    title: 'Header',
    tabs: 'orthographyTabs',
  },
  {
    id: uuidv4(),
    icon: ListIcon,
    content: ListBuildBlock,
    text: 'This is an unordered list.',
    title: 'List',
    tabs: 'orthographyTabs',
  },
  {
    id: uuidv4(),
    icon: ButtonIcon,
    content: ButtonBuildBlock,
    text: 'Button',
    title: 'Button',
    tabs: 'buttonTabs',
  },
  {
    id: uuidv4(),
    icon: ImageIcon,
    content: ImageBuildBlock,
    title: 'Image',
    tabs: 'imageTabs',
  },
  {
    id: uuidv4(),
    icon: SpacingIcon,
    content: SpacingBuildBlock,
    title: 'Spacing',
    tabs: 'spacingTabs',
  },
  {
    id: uuidv4(),
    icon: DividerIcon,
    content: DividerBuildBlock,
    title: 'Divider',
    tabs: 'dividerTabs',
  },
  {
    id: uuidv4(),
    icon: SocialIcon,
    content: SocialBuildBlock,
    title: 'Social',
    tabs: 'socialTabs',
  },
  {
    id: uuidv4(),
    icon: HtmlIcon,
    content: HtmlBuildBlock,
    text: '<p style="color:green; font-weight:bold; text-align: center">Dinomail is the best</p>',
    title: 'HTML',
    tabs: 'htmlTabs',
  },
  {
    id: uuidv4(),
    icon: FooterIcon,
    content: FooterBuildBlock,
    text: 'Unsubscribe',
    title: 'Footer',
    tabs: 'footerTabs',
  },
  {
    id: uuidv4(),
    icon: VideoIcon,
    content: VideoBuildBlock,
    url: '',
    title: 'Video',
    tabs: 'videoTabs',
  },
  {
    id: uuidv4(),
    icon: GiphyIcon,
    content: GiphyBuildBlock,
    title: 'Giphy',
    tabs: 'giphyTabs',
  },
];

export const initial_30_70_LayoutChildBlock = Array.from({ length: 2 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['30%', '70%'],
}));

export const initial_40_60_LayoutChildBlock = Array.from({ length: 2 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['40%', '60%'],
}));

export const initial_50_50_LayoutChildBlock = Array.from({ length: 2 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['50%', '50%'],
}));

export const initial_60_40_LayoutChildBlock = Array.from({ length: 2 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['60%', '40%'],
}));

export const initial_70_30_LayoutChildBlock = Array.from({ length: 2 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['70%', '30%'],
}));

export const initial_3_by_33_LayoutChildBlock = Array.from({ length: 3 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['33%', '33%', '33%'],
}));

export const initial_25_25_50_LayoutChildBlock = Array.from({ length: 3 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['25%', '25%', '50%'],
}));

export const initial_25_50_25_LayoutChildBlock = Array.from({ length: 3 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['25%', '50%', '25%'],
}));

export const initial_50_25_25_LayoutChildBlock = Array.from({ length: 3 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['50%', '25%', '25%'],
}));

export const initial_4_by_25_LayoutChildBlock = Array.from({ length: 4 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['25%', '25%', '25%', '25%'],
}));

export const initial_16_by_6_LayoutChildBlock = Array.from({ length: 6 }, () => ({
  id: uuidv4(),
  children: [],
  width: ['16%', '16%', '16%', '16%', '16%', '16%'],
}));

export const layoutBlocksInitialState = [
  {
    id: uuidv4(),
    icon: ThirtySeventyBlockIcon,
    content: LayoutContainerBlock,
    child: initial_30_70_LayoutChildBlock,
    width: ['30%', '70%'],
  },
  {
    id: uuidv4(),
    icon: FortySixtyBlockIcon,
    content: LayoutContainerBlock,
    child: initial_40_60_LayoutChildBlock,
  },
  {
    id: uuidv4(),
    icon: FiftyFiftyBlockIcon,
    content: LayoutContainerBlock,
    child: initial_50_50_LayoutChildBlock,
  },
  {
    id: uuidv4(),
    icon: SixtyFortyBlockIcon,
    content: LayoutContainerBlock,
    child: initial_60_40_LayoutChildBlock,
  },
  {
    id: uuidv4(),
    icon: SeventyThirtyBlockIcon,
    content: LayoutContainerBlock,
    child: initial_70_30_LayoutChildBlock,
  },
  {
    id: uuidv4(),
    icon: ThirtyThreeBlockIcon,
    content: LayoutContainerBlock,
    child: initial_3_by_33_LayoutChildBlock,
  },
  {
    id: uuidv4(),
    icon: TwentyFiveFiftyBlockIcon,
    content: LayoutContainerBlock,
    child: initial_25_25_50_LayoutChildBlock,
  },
  {
    id: uuidv4(),
    icon: TwentyFiveFiftyTwentyFiveBlockIcon,
    content: LayoutContainerBlock,
    child: initial_25_50_25_LayoutChildBlock,
  },
  {
    id: uuidv4(),
    icon: FiftyTwentyFiveBlockIcon,
    content: LayoutContainerBlock,
    child: initial_50_25_25_LayoutChildBlock,
  },
  {
    id: uuidv4(),
    icon: TwentyFiveByFourBlockIcon,
    content: LayoutContainerBlock,
    child: initial_4_by_25_LayoutChildBlock,
  },
  {
    id: uuidv4(),
    icon: SixteenSixBlockIcon,
    content: LayoutContainerBlock,
    child: initial_16_by_6_LayoutChildBlock,
  },
];

export const initHtml =
  '<p style="color:green; font-weight:bold; text-align: center">Dinomail is the best</p>';

export const initEditorMediaQuery = 600;
export const initDesktopMediaQuery = 700;
export const initMobileMediaQuery = 320;
